/** 一些公共方法 */
/**转换时间 */
export function formateDate(datetime) {
	// let  = "2019-11-06T16:00:00.000Z"
		function addDateZero(num) {
				return (num < 10 ? "0" + num : num);
		}
		const d = new Date(datetime);
		const formatdatetime = d.getFullYear() + '-' + addDateZero(d.getMonth() + 1) + '-' + addDateZero(d.getDate());
		return formatdatetime;
}
/** yyyymmdd(new Date) -> "2018-07-23" */
export function yyyymmdd(date, delimiter = '-') {
	const yyyy = date.getFullYear().toString();
	const mm = (date.getMonth() + 1).toString();
	const dd = date.getDate().toString();

	return yyyy + delimiter + (mm[1] ? mm : `0${mm[0]}`)
		+ delimiter + (dd[1] ? dd : `0${dd[0]}`);
}

/** yymmddhhmm(new Date) 2018-07-23 14:55 */
export function yymmddhhmm(date, delimiter = '-') {
	const yy = date.getFullYear().toString();
	const mm = (date.getMonth() + 1).toString();
	const dd = date.getDate().toString();
	const hh = date.getHours().toString();
	const MM = date.getMinutes().toString();

	return yy + delimiter + (mm[1] ? mm : `0${mm[0]}`) + delimiter + (dd[1] ? dd : `0${dd[0]}`)
		+ ' ' + (hh[1] ? hh : `0${hh[0]}`) + ':' + (MM[1] ? MM : `0${MM[0]}`);
}

/**
 * 过滤掉对象中值为 false(不为数字) 的属性
 * 需注意，可能被过滤掉的属性应为可选属性 (否则可能会发送不安全的问题)
 * 过滤的数据不应是嵌套类型，否则可能会有引用的问题
 *
 * @export
 * @template T 返回的数据的类型
 * @param {*} obj
 * @param {boolean} is_filter_zero 是否过滤 0 值
 * @returns {T}
 */
export function filter_empty_value(obj, is_filter_zero = false) {
	const result = {};

	for (const key in obj) {
		if (obj[key] || (!is_filter_zero && typeof obj[key] === 'number')) {
			result[key] = obj[key];
		}
	}

	return result;
}

export function downloadImg(data, fileName) {
	const src = data;
	const canvas = document.createElement('canvas');
	const img = document.createElement('img');
	img.onload = function(e) {
			canvas.width = img.width;
			canvas.height = img.height;
			const context = canvas.getContext('2d');
			context.drawImage(img, 0, 0, img.width, img.height);
			// window.navigator.msSaveBlob(canvas.msToBlob(),'image.jpg');
			// saveAs(imageDataUrl, '附件');
			canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
			canvas.toBlob((blob) => {
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);
					link.download = fileName; 
					link.click();  
			}, "image/jpeg");
			// context.toBlob(function(blob) {
			// console.log('blob :', blob);
			

			// let link = document.createElement('a');
			// link.href = window.URL.createObjectURL(blob);
			// link.download = 'aaa';
			// }, "image/jpeg");
	};
	img.setAttribute("crossOrigin", 'anonymous');
	img.src = src;
}

export function downloadImg2(data) {
	const link = document.createElement('a');
	const url = data;
	// 这里是将url转成blob地址，
	fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
		link.href = URL.createObjectURL(blob);
		console.log(link.href);
		link.download = '';
		document.body.appendChild(link);
		link.click();
	});
}
/**
 * 下载文件
 * @param {文件返回json} data 
 * @param {文件名} fileName 
 */
export function downlodFile(data, fileName) {
	const url = window.URL.createObjectURL(new Blob([data])); //创建下载链接
	console.log(url);
	const link = document.createElement('a'); //创建a标签
	link.style.display = 'none';  //将a标签隐藏
	link.href = url;  //给a标签添加下载链接
	link.setAttribute('download', fileName); // 此处注意，要给a标签添加一个download属性，属性值就是文件名称 否则下载出来的文件是没有属性的，空白白
	document.body.appendChild(link);
	link.click();  //执行a标签
	URL.revokeObjectURL(link.href); // 释放URL 对象
	document.body.removeChild(link);
}

/**
 * 滚动动画函数
 *
 * @export
 * @param {(HTMLElement | Window)} el
 * @param {number} [from=0]
 * @param {number} to
 * @param {number} [duration=500]
 */
export function scroll_to(el, from = 0, to, duration = 500) {
	function callback() {
		return window.setTimeout(callback, 1000 / 60);
	}

	if (!window.requestAnimationFrame) {
		window.requestAnimationFrame = (
			window.webkitRequestAnimationFrame
			|| window.mozRequestAnimationFrame
			|| window.msRequestAnimationFrame
			|| callback
		);
	}
	const difference = Math.abs(from - to);
	const scroll_step = Math.ceil(difference / duration * 50);

	function scroll(start, end, step) {
		if (start === end) {
			return;
		}

		let d = (start + step > end) ? end : start + step;

		if (start > end) {
			d = (start - step < end) ? end : start - step;
		}

		if (el === window) {
			window.scrollTo(d, d);
		} else {
			el.scrollTop = d;
		}

		window.requestAnimationFrame(() => scroll(d, end, step));
	}

	scroll(from, to, scroll_step);
}

/**
 * 遍历滚动函数
 *
 * @export
 * @param {number} [target_top=0]
 * @param {number} [duration=500]
 */
export function from_current_top_scroll_to(target_top = 0, duration = 500) {
	const current_top = document.documentElement.scrollTop || document.body.scrollTop;

	scroll_to(window, current_top, target_top, duration);
}

/** 根据身份证号计算年龄 */
export function getAge(identityCard) {
	const len = (identityCard + '').length;

	if (len === 0) {
		return 0;
	} else if ((len !== 15) && (len !== 18)) { // 身份证号码只能为15位或18位其它不合法
		return 0;
	}
	let strBirthday = '';
	if (len === 18) { // 处理18位的身份证号码从号码中得到生日和性别代码
		strBirthday = identityCard.substr(6, 4) + '/' + identityCard.substr(10, 2) + '/' + identityCard.substr(12, 2);
	}
	if (len === 15) {
		strBirthday = '19' + identityCard.substr(6, 2) + '/' + identityCard.substr(8, 2) + '/' + identityCard.substr(10, 2);
	}
	// 时间字符串里，必须是“/”
	const birthDate = new Date(strBirthday);
	const nowDateTime = new Date();
	let age = nowDateTime.getFullYear() - birthDate.getFullYear();

	// 再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
	if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() === birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
		age -= 1;
	}
	return age;
}

/** 删除节点 */
export function node_remove(dom) {
	try {
		if (dom.remove) {
			dom.remove();
		} else if (dom.removeNode) {
			dom.removeNode();
		}
	} catch (e) {
		console.log(e);
	 }
}

/** 通过 iframe 下载文件 */
export async function use_iframe_download(download_path) {
	const $iframe = document.createElement('iframe');

	$iframe.style.height = '0px';
	$iframe.style.width = '0px';
	document.body.appendChild($iframe);
	$iframe.setAttribute('src', download_path);

	setTimeout(() => { node_remove($iframe); }, 20000);
}
/**
 * useFormDownload function - 通过 form 下载文件
 *
 * @param  {String} method http method
 * @param  {String} action 后端接口
 * @param  {Object} params 请求参数
 * @return {Void}
 */
export async function use_form_download(method, action, params) {
  const inputs = Object.keys(params).map((key) => {
      return `<input type="hidden" name="${key}" value='${params[key]}'/>`;
  }).join('');

  const $form = document.createElement('form');

  $form.action = action;
  $form.method = method;
  $form.innerHTML = inputs;

  document.body.appendChild($form);

  $form.submit();

  setTimeout(() => {
    node_remove($form);
  }, 10000);
}

/** 获取图片的宽高 */
export function get_image_dom_natural_wh(img_dom) {
  return new Promise((resolve) => {
    if (img_dom.naturalWidth && img_dom.naturalHeight) {
      return resolve({ width: img_dom.naturalWidth, height: img_dom.naturalHeight });
    } else {
      const img = new Image();

      img.src = img_dom.src;
      img.onload = () => {
        return resolve({ width: img.width, height: img.height });
      };
      img.onerror = () => {
        return resolve({ width: 0, height: 0 });
      };
    }
  });
}

/** 根据图片 url 获取图片的宽高 */
export function get_image_natural_wh(url) {
  return new Promise((resolve) => {
    const img = new Image();

    img.src = url;
    img.onload = () => {
      return resolve({ width: img.width, height: img.height });
    };
    img.onerror = () => {
      return resolve({ width: 0, height: 0 });
    };
  });
}

/** 判断是否有该 classname */
export function has_class(el, cls) {
  if (!el || !cls) { return false; }
  if (cls.indexOf(' ') !== -1) { throw new Error('className should not contain space.'); }
  if (el.classList) {
    return el.classList.contains(cls);
  } else {
    return (' ' + el.className + ' ').indexOf(' ' + cls + ' ') > -1;
  }
}
/**
 * 获取祖先节点中指定 class 的节点元素 (只是匹配 class 有指定的字符，并不是完整匹配)
 *
 * @export
 * @param {HTMLElement} dom
 * @param {string} class_name
 * @returns {(HTMLElement | null)}
 */
export function find_parent_dom_base_class(dom, class_name) {
  const $target = dom.parentNode;

  if (!$target || ($target === document.body)) { return null; }
  if (has_class($target, class_name)) { return $target; }

  return find_parent_dom_base_class($target, class_name);
}